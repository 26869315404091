/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import * as PropTypes from "prop-types"
import classNames from "classnames"

function Card({
    children,
    color,
    className,
}) {
    return (
        <div
            className={classNames([
                { "bg-red-medium text-white": color === "red" },
                { "bg-orange-light text-red-medium": color === "red-contrast" },
                "block my-4 px-4 py-1 md:py-4 lg:px-24",
                "rounded-xl",
                // { "text-md px-24 py-4": size === "sm" },
                // { "text-lg px-32 py-16": size === "lg" },
                className,
            ])}
        >
            {children}
        </div>
    )
}

Card.propTypes = {
    children: PropTypes.node,
    color: PropTypes.oneOf([
        "red",
        "red-contrast",
    ]),
    className: PropTypes.string,
}

Card.defaultProps = {
    children: undefined,
    color: "red",
    className: undefined,
}

export default Card
