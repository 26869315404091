/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { Oval } from "react-loader-spinner"
import TopNavItem from "../../lib/components/TopNavItem"
import Button from "../../lib/components/Button"
import { useWallet } from "../providers/WalletProvider"
import HibbyLogoSmall from "../../assets/hibby_text.png"

function TopMenu() {
    const {
        isConnected,
        isConnecting,
        startWalletConnection,
        stopWalletConnection,
        address,
    } = useWallet()

    const connectionButton = () => (isConnecting ? (
        <div className="w-28 md:w-32">
            <div className="w-6 h-6 mx-auto">
                <Oval color="#B74C55" secondaryColor="#FED5AB" height={24} width={24} className="text-center" />
            </div>
        </div>
    ) : (
        <Button color="orange" onClick={() => startWalletConnection()} className="w-28 md:w-32">
            Connect
        </Button>
    ))

    return (
        <div className="flex py-4 px-4 md:px-0">
            <TopNavItem to="/" className="w-28 md:w-32">Home</TopNavItem>

            <a href="https://hibby.money/" className="flex-1">
                <img
                    src={HibbyLogoSmall}
                    alt="Hibby website"
                    className="mx-auto"
                    width={65}
                    height={31}
                />
            </a>

            {isConnected ? (
                <Button color="orange" onClick={() => stopWalletConnection()} className="w-28 md:w-32 truncate ...">
                    {address}
                </Button>
            ) : connectionButton()}
        </div>
    )
}

export default TopMenu
