import { makeCancelable } from "../../lib/util/usePromise"
import {
    connectWallet,
    disconnectWallet,
    getTokensOwned,
    postApproveAllowance,
} from "../services/walletService"

export const LOAD = "LOAD"
export const LOGOUT = "LOGOUT"
export const SET_WALLET_CONNECTED = "SET_WALLET_CONNECTED"
export const SET_LOADING_ERROR = "SET_LOADING_ERROR"
export const SET_TOKENS_OWNED = "SET_TOKENS_OWNED"
export const UPDATE_ALLOWANCE = "UPDATE_ALLOWANCE"
export const CANCEL_LOADING = "CANCEL_LOADING"

// Initial state
export const initialState = {
    address: undefined,
    userInfo: undefined,
    error: undefined,
    isLoading: false,
    isConnected: false,
}

// Reducer
const reducer = {
    [LOGOUT]: () => ({ ...initialState }),
    [LOAD]: (state) => ({ ...state, isLoading: true }),
    [SET_WALLET_CONNECTED]: (state, data) => ({
        ...initialState,
        address: data.address,
        isConnected: true,
        userInfo: data.userInfo,
    }),
    [SET_TOKENS_OWNED]: (state, tokensOwned) => ({ ...state, userInfo: tokensOwned }),
    [CANCEL_LOADING]: (state) => ({ ...state, isLoading: false }),
    [UPDATE_ALLOWANCE]: (state, busdAllowance) => ({ ...state, userInfo: { ...state.userInfo, busdAllowance }, isLoading: false }),
    [SET_LOADING_ERROR]: (state, error) => ({ ...initialState, error }),
}

export const walletReducer = (state, action) => (
    reducer[action.type](state, action.payload)
)

// Getters
export const getAddress = (state) => state.address
export const getUserInfo = (state) => state.userInfo
export const getIsConnected = (state) => state.isConnected
export const getIsLoading = (state) => state.isLoading
export const getError = (state) => state.error

// Action creators
export const updateOwnedTokens = (dispatch, address) => makeCancelable(
    getTokensOwned(address)
        .then((r) => {
            // console.log(r)
            dispatch({ type: SET_TOKENS_OWNED, payload: r })
        })
        .catch((e) => {
            // console.log(e)
            // if (!e.isCanceled) {
            //     console.log(e)
            // }
        }),
)

export const approveAllowance = (dispatch, amount) => {
    dispatch({ type: LOAD })
    return makeCancelable(
        postApproveAllowance(amount)
            .then((r) => {
                dispatch({ type: UPDATE_ALLOWANCE, payload: r })
            })
            .catch((e) => {
                dispatch({ type: CANCEL_LOADING })
                // if (!e.isCanceled) {
                //     console.log(e)
                // }
            }),
    )
}

export const startWalletConnection = (dispatch) => {
    dispatch({ type: LOAD })
    return makeCancelable(
        connectWallet()
            .then((r) => {
                dispatch({ type: SET_WALLET_CONNECTED, payload: r })
            })
            .catch((e) => {
                if (!e.isCanceled) {
                    dispatch({ type: SET_LOADING_ERROR, payload: e })
                } else {
                    dispatch({ type: LOGOUT }) // Connection was cancelled
                }
            }),
    )
}

export const stopWalletConnection = (dispatch) => {
    disconnectWallet()
    dispatch({ type: LOGOUT })
}
