import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { FirebaseAnalyticsLocationTracker } from "./config/firebase"
import { WalletProvider } from "./main/providers/WalletProvider"
import { PrivateSaleProvider } from "./main/providers/PrivateSaleProvider"

ReactDOM.render(
    <React.StrictMode>
        <WalletProvider>
            <PrivateSaleProvider>
                <Router>
                    <FirebaseAnalyticsLocationTracker />
                    <App />
                </Router>
            </PrivateSaleProvider>
        </WalletProvider>
    </React.StrictMode>,
    document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
