import React from "react"
import GorrilaGordoComingSoon from "../../assets/gorilla_gordo_coming_soon.png"

function GorrilaGordoLink() {
    return (
        <div className="border-4 border-blue-light rounded-xl p-8 my-8">
            <a
                href="https://gorilagordo.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="underline"
            >
                <img
                    src={GorrilaGordoComingSoon}
                    alt="Gorrila gordo coming soon"
                    className="mx-auto"
                />
            </a>

        </div>
    )
}

GorrilaGordoLink.propTypes = {
}

export default GorrilaGordoLink
