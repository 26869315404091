import React from "react"
import moment from "moment"
import * as PropTypes from "prop-types"
import Typo from "../../lib/components/Typo"

function PresaleProgress({
    info,
}) {
    return (
        <Typo className="border-white border-2 rounded-xl block mx-4" variant="h1" align="center" color="white">
            {info.isPrivateSaleAvailable ? (
                <>
                    Countdown {moment().to(info.saleEndsAt)}
                    <span className="lg:hidden"><br /></span>
                    <span className="hidden lg:inline"> / </span>
                    Supply: {info.totalSupply.subtract(info.sold).format()}
                </>
            ) : (
                <>Private sale sold out!</>
            )}

        </Typo>
    )
}

PresaleProgress.propTypes = {
    info: PropTypes.shape({
        saleEndsAt: PropTypes.object.isRequired,
        totalSupply: PropTypes.object.isRequired,
        sold: PropTypes.object.isRequired,
        isPrivateSaleAvailable: PropTypes.bool.isRequired,
    }).isRequired,
}

export default PresaleProgress
