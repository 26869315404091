import React, { useEffect } from "react"
import Navigation from "./lib/components/Navigation"
import Main from "./main/Main"
import TopMenu from "./main/components/TopMenu"
import { usePrivateSale } from "./main/providers/PrivateSaleProvider"

function App() {
    const { loadPrivateSaleInfo, updateTokensSold } = usePrivateSale()
    useEffect(() => {
        loadPrivateSaleInfo()
        const interval = setInterval(() => {
            updateTokensSold()
        }, 10000)
        return () => { clearInterval(interval) }
    }, [])

    return (
        <div className="h-screen">
            <Navigation>
                <TopMenu />
            </Navigation>
            <div className="h-full">
                <Main />
            </div>
        </div>
    )
}

export default App
