import React from "react"
import classNames from "classnames"
import * as PropTypes from "prop-types"
import SocialLinks from "./SocialLinks"
import Typo from "./Typo"

function PageWrapper({
    color,
    children,
}) {
    return (
        <div
            className={classNames([
                "absolute w-full min-h-full text-default text-white",
                color === "red" ? "bg-red-bright" : "bg-blue-medium",
            ])}
        >
            <div className="p-4 pt-12 md:pt-24">
                {children}
                <SocialLinks />
                <Typo
                    variant="body3"
                    align="center"
                    color="orange-light"
                    className="py-4"
                >
                    All rights reserved, Hibby money 2022
                </Typo>
            </div>
        </div>
    )
}

PageWrapper.defaultProps = {
    color: "blue",
}

PageWrapper.propTypes = {
    color: PropTypes.oneOf(["blue", "red"]),
}

export default PageWrapper
