/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import * as PropTypes from "prop-types"
import classNames from "classnames"

const classes = {
    h0: "font-header leading-lg text-xl tracking-wider md:text-xxxl md:leading-xxl md:tracking-widest uppercase",
    h1: "font-header leading-md text-sm tracking-wider md:text-xxl  md:leading-xl md:tracking-wider",
    h2: "font-header leading-sm text-xs tracking-wider md:text-lg md:leading-lg md:tracking-wider uppercase",
    body1: "font-body leading-md font-bold text-md tracking-tighter md:tracking-normal md:text-lg md:leading-lg",
    body2: "font-body  leading-sm md:leading-md font-bold text-xxs md:text-md tracking-wider md:tracking-tighter",
    body3: "font-body leading-sm font-bold text-xxs tracking-wider uppercase",
}

function Typo({
    children,
    variant,
    component,
    align,
    color,
    gutterBottom,
    className,
    ...elementProps
}) {
    const Component = component
    return (
        <Component
            className={classNames([
                classes[variant],
                `text-${align}`,
                `text-${color}`,
                gutterBottom ? "mb-4" : undefined,
                className,
            ])}
            {...elementProps}
        >
            {children}
        </Component>
    )
}

Typo.propTypes = {
    children: PropTypes.node,
    component: PropTypes.elementType,
    variant: PropTypes.oneOf(["h0", "h1", "h2", "body1", "body2", "body3"]),
    align: PropTypes.oneOf(["left", "right", "center", "justify"]),
    color: PropTypes.oneOf([
        "default",
        "inherit",
        "white",
        "orange-light",
    ]),
    gutterBottom: PropTypes.bool,
    className: PropTypes.string,
}

Typo.defaultProps = {
    children: undefined,
    variant: "body2",
    component: "p",
    align: "left",
    color: "inherit",
    gutterBottom: false,
    className: undefined,
}

export default Typo
