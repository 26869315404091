import React from "react"

import { ReactComponent as IcDiscord } from "../../assets/ic_discord.svg"
import { ReactComponent as IcInsta } from "../../assets/ic_insta.svg"
import { ReactComponent as IcTelegram } from "../../assets/ic_telegram.svg"
import { ReactComponent as IcTiktok } from "../../assets/ic_tiktok.svg"
import { ReactComponent as IcTwitter } from "../../assets/ic_twitter.svg"

function SocialLinks() {
    return (
        <div className="flex flex-row justify-center gap-2 md:mt-24">
            <a
                href="https://discord.gg/YTECY27EZw"
                target="_blank"
                rel="noreferrer noopener"
                className="grid border-orange-light border-4 rounded-full w-16 h-16 block place-content-center"
            >
                <IcDiscord />
            </a>
            <a
                href="https://instagram.com/hibbymoney"
                target="_blank"
                rel="noreferrer noopener"
                className="grid border-orange-light border-4 rounded-full w-16 h-16 block place-content-center"
            >
                <IcInsta />
            </a>
            <a
                href="https://t.me/hibbymoney"
                target="_blank"
                rel="noreferrer noopener"
                className="grid border-orange-light border-4 rounded-full w-16 h-16 block place-content-center"
            >
                <IcTelegram />
            </a>
            <a
                href="https://tiktok.com/@hibbymoney"
                target="_blank"
                rel="noreferrer noopener"
                className="grid border-orange-light border-4 rounded-full w-16 h-16 block place-content-center"
            >
                <IcTiktok />
            </a>
            <a
                href="https://twitter.com/hibbymoney"
                target="_blank"
                rel="noreferrer noopener"
                className="grid border-orange-light border-4 rounded-full w-16 h-16 block place-content-center"
            >
                <IcTwitter />
            </a>
        </div>
    )
}

SocialLinks.propTypes = {
}

export default SocialLinks
