import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent } from "firebase/analytics"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const firebaseConfig = {
    apiKey: "AIzaSyCiQ5JmWPKmwJ5seAI7v9aCoiD7RDzQMp4",
    authDomain: "hibby-money.firebaseapp.com",
    projectId: "hibby-money",
    storageBucket: "hibby-money.appspot.com",
    messagingSenderId: "234964901961",
    appId: "1:234964901961:web:131fb5df715dbe0fab9f98",
    measurementId: "G-QJTCZ6BJET",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

export function FirebaseAnalyticsLocationTracker() {
    const location = useLocation()
    useEffect(() => {
        const page = location.pathname
        logEvent(analytics, "screen_view", {
            firebase_screen: page,
        })
    }, [location])
    return null
}
