import React from "react"
import * as PropTypes from "prop-types"
import Typo from "../../lib/components/Typo"

function HibbyOwned({
    info,
}) {
    return (
        <Typo variant="body1" align="center" className="uppercase">
            You own <span className="text-yellow">{info.hibbyOwned.format()}</span><br />
            Purchased <span className="text-yellow">{info.hibbyPurchased.format()}</span><br />
            Referral rewards <span className="text-yellow">{info.rewardReceived.format()}</span><br />
        </Typo>
    )
}

HibbyOwned.propTypes = {
    info: PropTypes.shape({
        hibbyOwned: PropTypes.object.isRequired,
        hibbyPurchased: PropTypes.object.isRequired,
        rewardReceived: PropTypes.object.isRequired,
    }).isRequired,
}

export default HibbyOwned
