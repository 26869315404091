import React from "react"
import { Link } from "react-router-dom"
import PageWrapper from "../../lib/components/PageWrapper"
import Typo from "../../lib/components/Typo"
import Card from "../../lib/components/Card"
import Header from "../../lib/components/Header"
import PresaleProgress from "../components/PresaleProgress"
import GorrilaGordoLink from "../components/GorillaGordoLink"
import { usePrivateSale } from "../providers/PrivateSaleProvider"
import Button from "../../lib/components/Button"

function QrCode() {
    const { privateSaleInfo } = usePrivateSale()

    return (
        <PageWrapper>
            <div className="container mx-auto">
                <Card className="flex flex-col items-center">
                    <Header text="Qr code" />
                    <Typo variant="body1" align="center">
                        Congratulations!<br />
                    </Typo>
                    <Card color="red-contrast">
                        <Typo variant="body1" align="center">
                            You are now exclusively invited to the Hibby Money private sale on BSC! This gives you an exclusive invite-only
                            referral link which allows you to earn 5% commission on others who join via your link!<br />
                            <br />
                            To find out if you also won $1000 worth of Hibby Money,<br />
                            <br />
                            1. Join&nbsp;
                            <a
                                href="https://t.me/hibbymoney"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="underline"
                            >
                                t.me/hibbymoney
                            </a><br />
                            2. Follow&nbsp;
                            <a
                                href="https://twitter.com/hibbymoney"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="underline"
                            >
                                twitter.com/hibbymoney
                            </a><br />
                            3. Tweet #HibbyMoneyMagnet<br />
                            4. If you are the winner we will contact you soon!<br />
                            <br />
                            Also you are now exclusively invited to the Hibby Money private sale on BSC! This gives you an
                            exclusive invite-only referral link which allows you to earn 5% commission on others who
                            join via your link!<br />
                        </Typo>
                    </Card>
                    <Button
                        size="lg"
                        color="orange"
                        className="mb-16"
                        component={Link}
                        to="/private-sale?invite=0xc268eb851954168f824304e0acb7fbcee1e13e863e84d9b5607ba379c7d062a6"
                    >
                        Get your hibby tokens now
                    </Button>
                </Card>
                {privateSaleInfo && (
                    <PresaleProgress info={privateSaleInfo} />
                )}
                <GorrilaGordoLink />
            </div>
        </PageWrapper>
    )
}

export default QrCode
