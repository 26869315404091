import React from "react"
import { Link } from "react-router-dom"
import { Oval } from "react-loader-spinner"
import currency from "currency.js"
import PageWrapper from "../../lib/components/PageWrapper"
import Typo from "../../lib/components/Typo"
import Button from "../../lib/components/Button"
import Card from "../../lib/components/Card"
import Header from "../../lib/components/Header"
import PresaleProgress from "../components/PresaleProgress"
import GorrilaGordoLink from "../components/GorillaGordoLink"
import { usePrivateSale } from "../providers/PrivateSaleProvider"
import { useWallet } from "../providers/WalletProvider"
import HibbyOwned from "../components/HibbyOwned"

function Home() {
    const { privateSaleInfo, privateSaleLoadingState } = usePrivateSale()
    const { userInfo, isConnected } = useWallet()

    const loadedPrivateSaleContent = () => {
        if (!privateSaleInfo || !privateSaleInfo.isPrivateSaleAvailable) {
            if (!privateSaleLoadingState.isLoading) {
                return (isConnected ? (
                    <>
                        <HibbyOwned info={userInfo} />
                        <Typo variant="body1">
                            Hibby Money coming soon...
                        </Typo>
                    </>
                ) : (
                    <Typo variant="h2">Hibby Money coming soon...</Typo>
                ))
            }
            return (isConnected && (
                <HibbyOwned info={userInfo} />
            ))
        }

        return (
            <>
                {isConnected ? (
                    <HibbyOwned info={userInfo} />
                ) : (
                    <Typo variant="h2">Connect your wallet to join the private sale!</Typo>
                )}
                <Button
                    component={Link}
                    to="/private-sale"
                    size="lg"
                    color="orange"
                    className="my-8"
                >
                    Buy more
                </Button>
            </>
        )
    }

    return (
        <PageWrapper>
            <div className="container mx-auto">
                <Card className="flex flex-col items-center">
                    <Header text="Dashboard" />
                    {loadedPrivateSaleContent()}
                    {!privateSaleInfo && privateSaleLoadingState.isLoading && (
                        <div className="mx-auto my-12 w-10 h-10">
                            <Oval color="#B74C55" secondaryColor="#FED5AB" height={40} width={40} className="text-center" />
                        </div>
                    )}
                </Card>
                {privateSaleInfo && (
                    <PresaleProgress info={privateSaleInfo} />
                )}
                <GorrilaGordoLink />
            </div>
        </PageWrapper>
    )
}

export default Home
