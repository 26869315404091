import React, { useEffect } from "react"
import NumberFormat from "react-number-format"
import { Oval } from "react-loader-spinner"
import PageWrapper from "../../../lib/components/PageWrapper"
import Typo from "../../../lib/components/Typo"
import Card from "../../../lib/components/Card"
import Header from "../../../lib/components/Header"
import ShoppingCart from "./ShoppingCart"
import { usePrivateSale } from "../../providers/PrivateSaleProvider"
import { NOCURRENCY } from "../../services/walletService"

function PrivateSale() {
    const {
        privateSaleInfo,
        loadPrivateSaleInfo,
        privateSaleLoadingState,
    } = usePrivateSale()

    useEffect(() => {
        loadPrivateSaleInfo()
    }, [])

    if (privateSaleInfo && !privateSaleInfo.isPrivateSaleAvailable) {
        return (
            <PageWrapper>
                <div className="container mx-auto">
                    <Card>
                        <Header text="Sold out" />
                        <Typo variant="body2">The private sale is sold out. Please buy your hibby at an exchange. (Link to exchange?)</Typo>
                    </Card>
                </div>
            </PageWrapper>
        )
    }

    return (
        <PageWrapper>
            <div className="container mx-auto">
                <Card>
                    <Header text="Private token sale" />
                    <Typo variant="body1" align="center" className="uppercase">Price</Typo>
                    {privateSaleInfo && (
                        <Card color="red-contrast">
                            <Typo variant="body1" className="uppercase" align="center">
                                Current price: {privateSaleInfo.currentPrice.format()}<br />
                                <br />
                                {NOCURRENCY(privateSaleInfo.priceIncreaseAtAmountSold.subtract(privateSaleInfo.sold)).format()}&nbsp;
                                left out of&nbsp;
                                {privateSaleInfo.priceIncreaseAtAmountSold.format()}<br />
                                before price increase to {privateSaleInfo.priceIncreaseTo.format()}
                            </Typo>
                        </Card>
                    )}
                </Card>

                {!privateSaleInfo && privateSaleLoadingState.isLoading && (
                    <div className="mx-auto my-12 w-10 h-10">
                        <Oval color="#B74C55" secondaryColor="#FED5AB" height={40} width={40} className="text-center" />
                    </div>
                )}
                {privateSaleLoadingState.error && (
                    <Card>{privateSaleLoadingState.error.message}</Card>
                )}
                {privateSaleInfo && (
                    <ShoppingCart info={privateSaleInfo} />
                )}
            </div>
        </PageWrapper>
    )
}

export default PrivateSale
