import React from "react"
import * as PropTypes from "prop-types"
import Typo from "./Typo"

function Header({
    text,
}) {
    return (
        <div className="flex flex-row mb-4">
            <div className="flex-1" />
            <Typo align="center" className="border-orange-medium border-dashed border-4 rounded-full px-16" variant="h1">
                {text}
            </Typo>
            <div className="flex-1" />
        </div>
    )
}

Header.propTypes = {
    text: PropTypes.string.isRequired,
}

export default Header
