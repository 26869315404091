import React, {
    createContext,
    useContext,
    useReducer,
} from "react"
import * as PropTypes from "prop-types"
import {
    getUserInfo,
    getError,
    getIsConnected,
    getIsLoading,
    initialState,
    startWalletConnection,
    stopWalletConnection,
    walletReducer,
    approveAllowance,
    getAddress,
    updateOwnedTokens,
} from "../store/walletReducer"

export const WalletContext = createContext({})

export const useWallet = () => useContext(WalletContext)

export function WalletProvider({ children, initial }) {
    const [state, dispatch] = useReducer(walletReducer, initial)
    const userInfo = getUserInfo(state)
    const address = getAddress(state)

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const providerContent = {
        startWalletConnection: () => startWalletConnection(dispatch),
        stopWalletConnection: () => stopWalletConnection(dispatch, state),
        approveAllowance: (amount) => approveAllowance(dispatch, amount),
        updateOwnedTokens: () => updateOwnedTokens(dispatch, address),
        isConnected: getIsConnected(state),
        isConnecting: getIsLoading(state),
        connectionError: getError(state),
        userInfo,
        address,
    }

    return (
        <WalletContext.Provider value={providerContent}>
            {children}
        </WalletContext.Provider>
    )
}

WalletProvider.propTypes = {
    children: PropTypes.node,
    initial: PropTypes.object,
}

WalletProvider.defaultProps = {
    children: undefined,
    initial: initialState,
}
