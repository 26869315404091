import React from "react"
import * as PropTypes from "prop-types"
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import Button from "./Button"

function TopNavItem({ children, to, className }) {
    const resolved = useResolvedPath(to)
    const match = useMatch({ path: resolved.pathname, end: true })
    return (
        <Button
            color={match ? "blue-active" : "blue"}
            component={Link}
            to={to}
            className={className}
        >
            {children}
        </Button>
    )
}

TopNavItem.defaultProps = {
    className: undefined,
}

TopNavItem.propTypes = {
    children: PropTypes.node,
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
}

export default TopNavItem
