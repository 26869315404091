/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import * as PropTypes from "prop-types"
import classNames from "classnames"
import Typo from "./Typo"

function Button({
    children,
    size,
    component,
    color,
    className,
    ...elementProps
}) {
    const Component = component
    return (
        <Component
            type="button"
            className={classNames([
                "block rounded-xl disabled:bg-gray-400",
                { "bg-blue-dark text-white": color === "blue" },
                { "bg-orange-medium text-white": color === "orange" },
                { "bg-blue-extra-light text-white": color === "blue-active" },
                { "px-4 py-1": size === "sm" },
                { "px-10 py-2": size === "lg" },
                className,
            ])}
            {...elementProps}
        >
            <Typo variant={size === "lg" ? "h1" : "h2"} align="center" className="truncate">
                {children}
            </Typo>
        </Component>
    )
}

Button.propTypes = {
    children: PropTypes.node,
    component: PropTypes.elementType,
    size: PropTypes.oneOf(["sm", "lg"]),
    color: PropTypes.oneOf([
        "purple",
        "blue",
        "blue-active",
        "orange",
    ]),
    className: PropTypes.string,
}

Button.defaultProps = {
    children: undefined,
    size: "sm",
    component: "button",
    color: "blue",
    className: undefined,
}

export default Button
