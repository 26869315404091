import React, {
    createContext,
    useContext,
    useReducer,
} from "react"
import * as PropTypes from "prop-types"
import {
    buyTokens,
    getBuyTokensError,
    getIsBuyingTokens,
    getPrivateSaleInfo,
    getPrivateSaleLoadingState,
    initialState,
    loadPrivateSaleInfo,
    privateSaleReducer,
    updateTokensSold,
} from "../store/privateSaleReducer"

export const PrivateSaleContext = createContext({})

export const usePrivateSale = () => useContext(PrivateSaleContext)

export function PrivateSaleProvider({ children, initial }) {
    const [state, dispatch] = useReducer(privateSaleReducer, initial)

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const providerContent = {
        loadPrivateSaleInfo: () => loadPrivateSaleInfo(dispatch),
        buyTokens: (amount, invite) => buyTokens(dispatch, amount, invite),
        updateTokensSold: () => loadPrivateSaleInfo(dispatch),
        privateSaleInfo: getPrivateSaleInfo(state),
        isBuyingTokens: getIsBuyingTokens(state),
        buyTokensError: getBuyTokensError(state),
        privateSaleLoadingState: getPrivateSaleLoadingState(state),
    }

    return (
        <PrivateSaleContext.Provider value={providerContent}>
            {children}
        </PrivateSaleContext.Provider>
    )
}

PrivateSaleProvider.propTypes = {
    children: PropTypes.node,
    initial: PropTypes.object,
}

PrivateSaleProvider.defaultProps = {
    children: undefined,
    initial: initialState,
}
