import { get } from "lodash"
import moment from "moment"
import { makeCancelable } from "../../lib/util/usePromise"
import { fetchPrivateSaleInfo, fetchSoldInPrivateSale, postBuyTokens } from "../services/walletService"

export const LOADING_SALE_INFO = "LOADING_SALE_INFO"
export const LOADING_SALE_INFO_ERROR = "LOADING_SALE_INFO_ERROR"
export const SET_SALE_INFO = "SET_SALE_INFO"
export const UPDATE_TOKENS_SOLD = "UPDATE_TOKENS_SOLD"

export const BUY_TOKENS = "BUY_TOKENS"
export const BUY_TOKENS_FINISHED = "BUY_TOKENS_FINISHED"

// Initial state
export const initialState = {
    loadingSaleInfo: false,
    loadingSaleError: undefined,
    privateSaleInfo: undefined,
    error: undefined,
    isLoading: false,
}

// Reducer
const reducer = {
    [LOADING_SALE_INFO]: (state) => ({
        ...state,
        loadingSaleInfo: true,
    }),
    [SET_SALE_INFO]: (state, info) => ({
        ...state,
        loadingSaleInfo: false,
        privateSaleInfo: info,
        loadingSaleError: undefined,
    }),
    [UPDATE_TOKENS_SOLD]: (state, tokensSold) => {
        if (!state.privateSaleInfo || state.privateSaleInfo.sold === tokensSold) {
            return state
        }
        return {
            ...state,
            privateSaleInfo: {
                ...state.privateSaleInfo,
                sold: tokensSold,
            },
        }
    },
    [LOADING_SALE_INFO_ERROR]: (state, error) => ({
        ...state,
        loadingSaleInfo: false,
        privateSaleInfo: undefined,
        loadingSaleError: error,
    }),
    [BUY_TOKENS]: (state) => ({
        ...state,
        error: undefined,
        isLoading: true,
    }),
    [BUY_TOKENS_FINISHED]: (state, error) => ({
        ...state,
        error,
        isLoading: false,
    }),
}

export const privateSaleReducer = (state, action) => (
    reducer[action.type](state, action.payload)
)

// Getters
export const getPrivateSaleLoadingState = (state) => ({
    isLoading: get(state, "loadingSaleInfo") ?? true,
    error: get(state, "loadingSaleError"),
})
export const getPrivateSaleInfo = (state) => {
    if (!state.privateSaleInfo) { return undefined }
    const info = get(state, "privateSaleInfo")
    return {
        ...info,
        isPrivateSaleAvailable: info.saleEndsAt.isAfter(moment()) && info.totalSupply.value > info.sold.value,
    }
}
export const getIsBuyingTokens = (state) => get(state, "isLoading")
export const getBuyTokensError = (state) => get(state, "error")

// Action creators
export const loadPrivateSaleInfo = (dispatch) => {
    dispatch({ type: LOADING_SALE_INFO })

    return makeCancelable(
        fetchPrivateSaleInfo()
            .then((r) => {
                // console.log(r)
                dispatch({
                    type: SET_SALE_INFO,
                    payload: r,
                })
            }).catch((e) => {
                // console.log(e)
                if (!e.isCanceled) {
                    dispatch({ type: LOADING_SALE_INFO_ERROR, payload: e })
                }
                throw e
            }),
    )
}

export const updateTokensSold = (dispatch) => makeCancelable(
    fetchSoldInPrivateSale()
        .then((r) => {
            dispatch({
                type: UPDATE_TOKENS_SOLD,
                payload: r,
            })
        }).catch((e) => {
            // if (!e.isCanceled) {
            //     console.log(e)
            // }
        }),
)

export const buyTokens = (dispatch, amount, invite) => {
    dispatch({ type: BUY_TOKENS })

    return makeCancelable(
        postBuyTokens(amount, invite)
            .then((r) => {
                dispatch({
                    type: BUY_TOKENS_FINISHED,
                    payload: undefined,
                })
                return r
            }).catch((e) => {
                if (!e.isCanceled) {
                    dispatch({ type: BUY_TOKENS_FINISHED, payload: e })
                }
                throw e
            }),
    )
}
