import { useEffect, useState } from "react"

export const makeCancelable = (promiseOrFunction) => {
    let hasCanceled = false
    const promise = (typeof promiseOrFunction === "function")
        ? promiseOrFunction()
        : promiseOrFunction

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            // eslint-disable-next-line prefer-promise-reject-errors
            (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
            // eslint-disable-next-line prefer-promise-reject-errors
            (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error)),
        )
    })

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled = true
        },
    }
}

export function usePromise(promiseOrFunction, defaultValue, dependencies) {
    const [state, setState] = useState({ value: defaultValue, error: null, isPending: true })

    useEffect(() => {
        const promise = (typeof promiseOrFunction === "function")
            ? promiseOrFunction()
            : promiseOrFunction

        const cancelable = makeCancelable(promise)
        cancelable.promise
            .then((value) => setState({ value, error: null, isPending: false }))
            .catch((reason) => {
                if (!reason.isCanceled) {
                    setState({ value: null, error: reason, isPending: false })
                } else {
                    // console.log("Canceled promise")
                }
            })

        return () => { cancelable.cancel() }
        // eslint-disable-next-line
    }, dependencies)

    const { value, error, isPending } = state
    return [value, error, isPending, setState]
}
