import currency from "currency.js"
import { ethers } from "ethers"
import moment from "moment"
// import { getNetwork } from "@ethersproject/networks"
import Web3Modal from "web3modal"
import BUSDAbi from "./BUSDAbi"
import hibbyAbi from "./HibbyAbi"
import hibbyPrivateSaleAbi from "./HibbyPrivateSaleAbi"

const testNet = "https://bsc-dataseed1.binance.org/"

const busdAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
const hibbyAddress = "0xF244bbB255A13aEb3632f5c282cFE97F80cE3583"
const privateSaleAddress = "0xA97367AE12b5A248C8C1BCe933210f4B34368e8B"

const provider = new ethers.providers.JsonRpcProvider(testNet)
const privateSaleContract = new ethers.Contract(privateSaleAddress, hibbyPrivateSaleAbi, provider)

export const HIBBY = (value) => currency(value, {
    symbol: " HIBBY",
    precision: 0,
    separator: ",",
    pattern: "# !",
})

export const BUSD = (value) => currency(value, {
    symbol: " BUSD",
    decimal: ".",
    separator: ",",
    pattern: "# !",
})

export const NOCURRENCY = (value) => currency(value, {
    symbol: "",
    decimal: ".",
    separator: ",",
    precision: 0,
})

let signer

const web3Modal = new Web3Modal({
    network: "mainnet",
    cacheProvider: true,
    providerOptions: {},
})

export const getTokensOwned = async (address) => {
    const hibbyContract = new ethers.Contract(hibbyAddress, hibbyAbi, provider)
    const busdContract = new ethers.Contract(busdAddress, BUSDAbi, provider)

    const hibbyOwnedPromise = hibbyContract.balanceOf(address)
    const busdAllowancePromise = busdContract.allowance(address, privateSaleAddress)
    const busdOwnedPromise = busdContract.balanceOf(address)
    const referralPromise = privateSaleContract.getReferralByAddress(address)
    const usersPromise = privateSaleContract.users(address)

    const hibbyOwned = await hibbyOwnedPromise
    const busdAllowance = await busdAllowancePromise
    const busdOwned = await busdOwnedPromise
    const referral = await referralPromise
    const users = await usersPromise

    return {
        hibbyOwned: HIBBY(ethers.utils.formatEther(hibbyOwned)),
        hibbyPurchased: HIBBY(ethers.utils.formatEther(users.tokenBrought)),
        rewardReceived: HIBBY(ethers.utils.formatEther(users.rewardRecieved)),
        busdOwned: HIBBY(ethers.utils.formatEther(busdOwned)),
        busdAllowance: HIBBY(ethers.utils.formatEther(busdAllowance)),
        referral,
    }
}

export const postApproveAllowance = async (amount) => {
    if (!signer) { throw new Error("No wallet connected") }
    const busdContact = new ethers.Contract(busdAddress, BUSDAbi, signer)
    await busdContact.approve(privateSaleAddress, ethers.utils.parseEther(`${amount.value}`))
    return amount
}

export const connectWallet = async () => {
    const instance = await web3Modal.connect()
    signer = new ethers.providers.Web3Provider(instance).getSigner()

    const address = await signer.getAddress()
    const userInfo = await getTokensOwned(address)
    return {
        address,
        userInfo,
    }
}

export const disconnectWallet = () => {
    signer = undefined
}

export const fetchSoldInPrivateSale = async () => {
    const tokenSold = await privateSaleContract.tokenSold()
    return HIBBY(ethers.utils.formatEther(tokenSold))
}

export const fetchPrivateSaleInfo = async () => {
    const pricePromise = privateSaleContract.checkBUSDPrice()
    const tokenSoldPromise = privateSaleContract.tokenSold()
    const endTimePromise = privateSaleContract.endTime()
    const maxSoldLimitPromise = privateSaleContract.maxSoldLimit()
    const priceChangeRatePromise = privateSaleContract.priceChangeRate()
    const increaseAtTokenPromise = privateSaleContract.increaseAtToken()
    const rewardPercentPromise = privateSaleContract.rewardPercent()

    const price = await pricePromise
    const currentPrice = BUSD(ethers.utils.formatEther(price))

    const priceChangeRate = await priceChangeRatePromise
    const currentChangeRate = BUSD(ethers.utils.formatUnits(priceChangeRate, 2))

    const tokenSold = await tokenSoldPromise
    const endTime = await endTimePromise
    const maxSoldLimit = await maxSoldLimitPromise
    const increaseAtToken = await increaseAtTokenPromise
    const rewardPercent = await rewardPercentPromise

    const a = ethers.utils.formatEther(increaseAtToken)
    const priceIncreaseAt = Math.ceil(ethers.utils.formatEther(tokenSold) / a) * a
    return {
        totalSupply: HIBBY(5000000),
        currentPrice,
        sold: HIBBY(ethers.utils.formatEther(tokenSold)),
        saleEndsAt: moment.unix(ethers.utils.formatUnits(endTime, 0)),
        maxBuyAmount: currentPrice.multiply(ethers.utils.formatEther(maxSoldLimit)),
        minBuyAmount: BUSD("1"),
        priceIncreaseAtAmountSold: HIBBY(priceIncreaseAt),
        priceIncreaseTo: currentPrice.add(currentChangeRate),
        rewardPercent: currency(ethers.utils.formatUnits(rewardPercent, 2)),
    }
}

// 0x61148792871baa2f1f1457abb003b2172ade30b61692ed0895ac2ddfdde9d1fa
export const postBuyTokens = async (amount, invite) => {
    if (!signer) { throw new Error("No wallet connected") }
    const presaleContract = new ethers.Contract(privateSaleAddress, hibbyPrivateSaleAbi, signer)
    const result = await presaleContract.buyTokens(
        ethers.utils.parseEther(`${amount.value}`),
        invite,
    )

    return result
}
