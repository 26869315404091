import React from "react"

function Navigation({ children }) {
    return (
        <header className="fixed z-50 w-full bg-navigation">
            <div className="container mx-auto">{children}</div>
        </header>
    )
}

export default Navigation
