/* eslint-disable no-unreachable */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react"
import * as PropTypes from "prop-types"
import { FacebookShareButton } from "react-share"
import { Link, useSearchParams } from "react-router-dom"
import ReactSlider from "react-slider"
import { Oval } from "react-loader-spinner"
import Typo from "../../../lib/components/Typo"
import Card from "../../../lib/components/Card"
import Header from "../../../lib/components/Header"
import Button from "../../../lib/components/Button"
import { usePrivateSale } from "../../providers/PrivateSaleProvider"
import { useWallet } from "../../providers/WalletProvider"
import { BUSD, HIBBY, NOCURRENCY } from "../../services/walletService"

function ShoppingCart({ info }) {
    const {
        buyTokens,
        isBuyingTokens,
        buyTokensError,
        loadPrivateSaleInfo,
    } = usePrivateSale()

    const {
        isConnected,
        isConnecting,
        userInfo,
        startWalletConnection,
        approveAllowance,
        updateOwnedTokens,
    } = useWallet()

    const [searchParams] = useSearchParams()

    const [invite, setInvite] = useState(searchParams.get("invite") ?? "")
    const [amount, setAmount] = useState(info.maxBuyAmount.divide(2))
    const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false)
    const [buyingDone, setBuyingDone] = useState(false)

    const hibbyAmount = HIBBY(amount.divide(info.currentPrice))
    const referalFee = hibbyAmount.multiply(info.rewardPercent)

    // if (userInfo) {
    //     return (
    //         <Card>
    //             <Header text="All done!" />
    //             <Typo variant="body2">Your tokens have been transeferred to you wallet.</Typo>
    //             <input
    //                 className="bg-orange-light text-red-medium rounded-xl block p-2 w-full"
    //                 type="text"
    //                 placeholder="Paste invitation code here"
    //                 value={`https://app.hibby.money/private-sale?invite=${userInfo.referral}`}
    //             />
    //             <div className="flex flex-row">
    //                 <FacebookShareButton />
    //             </div>
    //         </Card>
    //     )
    // }

    if (buyingDone) {
        return (
            <Card>
                <Header text="All done!" />
                <input
                    className="bg-orange-light text-red-medium rounded-xl block p-2 w-full"
                    type="text"
                    placeholder="Paste invitation code here"
                    value={`https://app.hibby.money/private-sale?invite=${userInfo.referral}`}
                    readOnly
                />
                <Typo variant="body2" align="center" className="py-8">
                    Use this private code to exclusively invite friends to the Hibby private sale before it closes!<br />
                    <br />
                    You will be rewarded with a 5% commission from every purchase made through your exclusive code!
                </Typo>
                <div className="flex flex-row">
                    <FacebookShareButton />
                </div>
            </Card>
        )
    }

    const buyInterface = () => {
        if (invite.length < 5) {
            return (
                <Button color="orange" type="submit" disabled>Invite code required</Button>
            )
        }

        if (!hasAgreedToTerms) {
            return (
                <Button color="orange" type="submit" disabled>Please accept terms</Button>
            )
        }

        if (isConnecting) {
            return (
                <div className="w-28">
                    <div className="w-6 h-6 mx-auto">
                        <Oval color="#B74C55" secondaryColor="#FED5AB" height={24} width={24} className="text-center" />
                    </div>
                </div>
            )
        }

        if (!isConnected) {
            return (
                <Button color="orange" onClick={() => { startWalletConnection() }}>
                    Connect wallet
                </Button>
            )
        }

        if (amount.value > userInfo.busdOwned.value) {
            return (
                <Button color="orange" type="submit" disabled>Insufficient funds</Button>
            )
        }
        if (amount.value > userInfo.busdAllowance.value) {
            return (
                <Button color="orange" onClick={() => { approveAllowance(amount) }}>
                    Approve BUSD access
                </Button>
            )
        }

        return (
            <Button color="orange" type="submit" disabled={isBuyingTokens}>Confirm</Button>
        )
    }

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                buyTokens(amount, invite).promise.then(() => {
                    setBuyingDone(true)
                    loadPrivateSaleInfo()
                    updateOwnedTokens()
                }).catch((ex) => {
                    // console.log(ex)
                })
            }}
        >
            <Card>
                <Header text="Select amount" />
                <Typo variant="body1" align="center" className="uppercase">Price</Typo>
                <div className="py-4">
                    <div>
                        <ReactSlider
                            min={info.minBuyAmount.value}
                            max={info.maxBuyAmount.value}
                            value={amount.value}
                            onChange={(value) => { setAmount(BUSD(value)) }}
                            trackClassName="bg-orange-light h-2 rounded-full"
                            thumbClassName="w-8 h-8 bg-orange-medium rounded-full -mt-3"
                            renderThumb={(props) => <div {...props} />}
                            step={1}
                        />
                    </div>
                </div>
                <div className="pb-4 flex flex-row justify-between">
                    <Typo color="orange-light" variant="body1">
                        {info.minBuyAmount.format()}
                    </Typo>
                    <Typo color="orange-light" variant="body1">
                        {info.maxBuyAmount.format()}
                    </Typo>
                </div>
                <Card color="red-contrast">
                    <Typo variant="body1" className="uppercase">
                        Hibby: {NOCURRENCY(hibbyAmount).format()}<br />
                        Referral fee ({NOCURRENCY(info.rewardPercent.multiply(100)).format()}%): -{referalFee.format()}<br />
                        You get: ~{hibbyAmount.subtract(referalFee).format()}<br />
                        Cost: {amount.format()}
                        <br />
                        (Not including transaction fees)
                    </Typo>
                </Card>

                <div className="my-8">
                    <Typo component="label" htmlFor="html" variant="body1" className="uppercase">Invite code</Typo>
                    <input
                        className="bg-orange-light text-red-medium rounded-xl block p-2 w-full"
                        type="text"
                        placeholder="Paste invitation code here"
                        value={invite}
                        onChange={(e) => { setInvite(e.target.value) }}
                    />
                </div>

                <Typo variant="body1" className="uppercase">
                    Confirm purchase<br />
                    ~{NOCURRENCY(amount.divide(info.currentPrice)).format()} Hibby tokens
                </Typo>

                {buyTokensError && (
                    <Card color="red-contrast">
                        <Typo variant="body1" className="uppercase">
                            {buyTokensError.message}
                        </Typo>
                    </Card>
                )}

                <div>
                    <Typo component="label" className="flex gap-4 items-center">
                        <input
                            className="w-6 h-6"
                            type="checkbox"
                            checked={hasAgreedToTerms}
                            onChange={(e) => { setHasAgreedToTerms(e.target.checked) }}
                        />
                        <span>
                            I have read and agree to the&nbsp;
                            <a
                                className="underline"
                                href="https://hibby.money/wp-content/uploads/2022/08/terms.pdf"
                                target="_blank"
                                rel="noreferrer"
                            >
                                terms and Conditions
                            </a>
                        </span>
                    </Typo>
                </div>

                <div className="py-4 flex flex-row justify-end gap-4 items-center">
                    <Typo component={Link} to="/" variant="body1">Back to dashboard</Typo>
                    {buyInterface()}
                </div>
            </Card>
        </form>
    )
}

ShoppingCart.propTypes = {
    info: PropTypes.shape({
        saleEndsAt: PropTypes.object.isRequired,
        minBuyAmount: PropTypes.object.isRequired,
        maxBuyAmount: PropTypes.object.isRequired,
        currentPrice: PropTypes.object.isRequired,
        sold: PropTypes.object.isRequired,
        rewardPercent: PropTypes.any.isRequired,
        isPrivateSaleAvailable: PropTypes.bool.isRequired,
    }).isRequired,
}

export default ShoppingCart
