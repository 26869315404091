import React from "react"
import PageWrapper from "../../../lib/components/PageWrapper"
import Card from "../../../lib/components/Card"
import Header from "../../../lib/components/Header"
import Typo from "../../../lib/components/Typo"
import GorrilaGordoBanner from "../../../assets/gorilla_gordo_banner.png"

function GorillaGordo() {
    return (
        <PageWrapper color="red">
            <div className="container mx-auto">
                <div className="border-4 border-red-extraLight rounded-xl p-8 my-8">
                    <img
                        src={GorrilaGordoBanner}
                        alt="Gorrila gordo coming soon"
                        className="mx-auto"
                    />
                    <Typo variant="body1" align="center">
                        Welcome to Gorila Gordo!<br />
                        <br />
                        Gorila Gordo is an exciting social crypto experiment for the HIBBY ecosystem.<br />
                        <br />
                        HIBBY holders can claim the full GG pot by depositing HIBBY into the contract address.
                        When this is done, a timer is reset and once it hits zero, the full amount in the GG pot
                        will be rewarded to the last person who deposited.<br />
                        <br />
                        Depending on how many tokens are deposited, a participant can choose how long it will take
                        before the timer hits zero. The more tokens you deposit, the less amount of time you need
                        before the full pot will be rewarded to you.<br />
                        <br />
                        Participating requires to connect a wallet to our dApp. We will always only ask permission
                        for a specific amount of tokens and never for the full balance! The first and last 3 digits
                        of  each participants wallet will be visible on the website in real time in chronological order.
                        And the only person entitled to the reward of the full GG is the last person who deposited
                        HIBBY tokens when the timer hits zero, nobody else!<br />
                        <br />
                        The Hibby Money project will deposit the first 1m HIBBY into the GG contract and it will
                        also deposit the unsold remainder (if any) of the private sale to the GG contract address.
                        Additionally 2% of the HIBBY buy/sell tax will automatically be forwarded to the GG wallet address.<br />
                        <br />
                        The HIBBY price after the private sale will be around $0.15 so the value of the GG pot
                        holding 1m tokens will be the equivalent of approximately $150k!<br />
                        <br />
                        Participation available soon, so stay tuned!
                    </Typo>
                </div>
            </div>
        </PageWrapper>
    )
}

export default GorillaGordo
