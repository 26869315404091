import React from "react"
import { Route, Routes } from "react-router-dom"
import GorillaGordo from "./pages/GorillaGordo/CorillaGordo"
import Home from "./pages/Home"
import PrivateSale from "./pages/PrivateSale/PrivateSale"
import QrCode from "./pages/QrCode"

const routes = [
    { path: "/", name: "Home", Component: Home },
    { path: "/private-sale", name: "PrivateSale", Component: PrivateSale },
    { path: "/qr", name: "Qr code", Component: QrCode },
    { path: "/gorillagordo", name: "Gorilla Gordo", Component: GorillaGordo },
]

function Main() {
    if (window.location.hostname === "app.gorilagordo.com" || window.location.hostname === "gg.localhost") {
        return (<GorillaGordo />)
    }
    return (
        <Routes>
            {routes.map(({ path, Component }) => <Route key={path} path={path} element={<Component />} />)}
        </Routes>
    )
}

export default Main
